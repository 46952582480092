<script>
import {prjbasicinfoDetail, prjbasicinfoSubmit, prjQrcode} from "@/api/safe/itemList";
import {dictionaryBiz,dictionary} from "@/api/reportTasks";
import qrcodeDialog from "@/views/projectSettings/regionManage/qrcode.vue";
import {getAreaData1} from "@/api/base/region";
import {getDeptLazyTree, getDeptTree} from "@/api/system/dept";
import ElTreeSelect from '@/components/ElTreeSelect';
import BaiduMap from "@/views/system/attendance/BaiduMap";
import {mapGetters} from "vuex";
export default {
  components: {qrcodeDialog,ElTreeSelect,BaiduMap},
  computed:{
    ...mapGetters([
      'userInfo',
    ]),
    // 基础信息按钮设置
    basicFormHeadBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.formType)) {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
      }
      result.push({
        label: "关闭",
        emit: "head-saveBack",
        type: "button",
        btnOptType: "close",
      });
      return result;
    },
  },
  data(){
    let that=this;
    return {
      qrcodeSrc: "",
      formType: "",
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now() - 3600 * 1000 * 24;
        },
      },
      planEndDateOptions: {
        disabledDate(time){
          return time.getTime()  < new Date(that.dataForm.startWorking).getTime();
        },
      },
      planStartDateOptions: {
        disabledDate(time){
          return time.getTime()+86400000  > new Date(that.dataForm.planEndDate).getTime();
        },
      },
      rules: {
        prjAs: [
          {
            required: true,
            message: "请输入项目简称",
            trigger: "blur",
          },
        ],
        contractId: [
          {
            required: true,
            message: "请输入执行单位",
            trigger: "change",
          },
        ],
        coordinate: [
          {
            required: true,
            message: "请选择项目坐标",
            trigger: "change",
          },
        ],
        latitude: [
          {
            required: true,
            message: "请选择项目坐标",
            trigger: "change",
          },
        ],
        prjJoinDate: [
          {
            required: true,
            message: "请选择项入网日期",
            trigger: "change",
          },
        ],
        areaId: [
          {
            required: true,
            message: "请选择项目位置",
            trigger: "change",
          },
        ],
        areaRestock: [
          {
            required: true,
            message: "请输入详细位置",
            trigger: "blur",
          },
        ],
        prjType: [
          {
            required: true,
            message: "请选择项目类型",
            trigger: "blur",
          },
        ],
      },
      areaDic: [], // 区域位置
      organDic: [], // 区域位置
      dataForm:{},
      showPage:true,
      prjDomesticList: [],
      prjTypeList: [],
      stageList: [],
      yes_no: [],
      businessTypeMap:[],// 项目类型字典
    }
  },
  mounted() {
    this.formType = this.$route.query.type || 'view';
    this.dataForm.id = this.$route.query.id || this.userInfo.dept_id;
    this.getPrjbasicinfoDetail();
    this.treeLoad();

  },
  methods:{
    treeLoad(){
      const parentId =this.userInfo.dept_id;
      getDeptTree('','2',parentId).then(res=>{
        this.organDic = res.data.data
      })
    },
    recursivefilter(arr, value) {
      return arr.filter(item => {
        if (item.deptCategory == 5) {
          return false
        }
        if (item.children && item.children.length > 0) {
          item.children = this.recursivefilter(item.children)
        }
        return true
      })
    },
    nodeClick(data, node, vm) {
      this.$forceUpdate()
      debugger
      this.dataForm.contractName = data.title;
    },
    closeDialog(){
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 保存
    headSave(boolean) {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.$loading();
          if (this.dataForm.areaId) {
            this.dataForm.areaId = this.dataForm.areaId.join(",");
          }
          prjbasicinfoSubmit(this.dataForm).then((res) => {
            if (res.data.code == 200) {
              this.$message.success('保存成功');
              this.getPrjbasicinfoDetail();
              this.$loading().close();
            }
          });
        }
      });
    },

    // 根据ID查表单详情
    getPrjbasicinfoDetail() {
      prjbasicinfoDetail({
        id: this.dataForm.id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.showPage=false
          let data=res.data.data
          this.$set(this,'dataForm',{
            ...res.data.data,
            prjType:String(data.prjType),
            prjInfoState:String(data.prjInfoState),
            coordinate:data.longitude ? `${data.longitude},${data.latitude}` : "",
            phoneCount:data.phoneCount != -1 ? data.phoneCount : "",
            areaId:data.areaId ? data.areaId.split(",") : [],
            progress: data.progress? Number(data.progress): 0
          });
          this.oldDataForm = {
            ...res.data.data,
          };
          this.getAreaData();
          this.getDictory();
          const scene = `${this.dataForm.id}&prj_code=${this.dataForm.prjCode}&prj_name=${this.dataForm.prjName}`;

          const toPage = "pages/login/register-account"; // 跳转地址
          prjQrcode({scene, toPage}).then((res) => {
            this.qrcodeSrc = "data:image/png;base64," + res.data.data;
          });
          this.$forceUpdate()
        }
      }).catch(err=>{
        this.showPage=false
      });
    },
    getDictory() {
      dictionaryBiz("domestic_overseas").then((res) => {
        this.prjDomesticList = res.data.data;
        this.dataForm.isDomestic = String(this.dataForm.isDomestic) || "1";
      });
      dictionaryBiz("biz_sector").then((res) => {
        this.prjTypeList = res.data.data;
      });
      dictionaryBiz("prj_stage").then((res) => {
        this.stageList = res.data.data;
      });
      dictionary("yes_no").then((res) => {
        this.yes_no = res.data.data;
      });
      dictionaryBiz("project_type").then((res) => {
        this.businessTypeMap = res.data.data;
      });
    },
    // 下载二维码
    downloadQrcode() {
      this.$refs.qrcodeDialog.init(
        this.qrcodeSrc,
        this.dataForm.prjName,
        this.dataForm.prjCode,
      );
    },
     // 放大二维码
    enLarageQrcode(){
      this.$refs.qrcodeDialog.init(
        this.qrcodeSrc,
        this.dataForm.prjName,
        this.dataForm.prjCode,
        '',
        true
      );
    },

    //   项目位置
    getAreaData(parentCode) {
      getAreaData1(parentCode, this.areaDic);
    },
    // 区域位置获取
    handleChangeArea(value) {
      const regionList =
        this.$refs["prjAreaCascader"].getCheckedNodes()[0].pathLabels;
      if (regionList && regionList.length != 0) {
        this.dataForm.areaName = regionList.join(",");
      }
    },
    //获取经纬度
    openMap() {
      this.$refs.BaiduMap.initMap()
    },

    handleMapSave(val) {
      this.$set(this.dataForm, "coordinate", `${val.longitude},${val.latitude}`);
    },
  },
}
</script>

<template>
  <el-container v-loading="showPage">
    <div v-if="!showPage" style="width: 100%; background: #ffffff" class="formMain">
      <head-layout
        :showIcon="false"
        head-title="项目概况"
        :head-btn-options="basicFormHeadBtnOptions"
        @head-save="headSave(false)"
        @head-saveBack="closeDialog"
      ></head-layout>
      <el-form
        ref="dataForm"
        :model="dataForm"
        label-width="160px"
        :disabled="formType == 'view'"
        :rules="rules"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="项目编码" prop="prjCode">
              <el-input
                v-model="dataForm.prjCode"
                disabled
                placeholder="请输入项目编码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目名称" prop="prjName">
              <el-input
                v-model="dataForm.prjName"
                disabled
                placeholder="请输入项目名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="">
              <div
                style="
                            width: 150px;
                            height: 150px;
                            position: absolute;
                            text-align: center;
                            z-index: 1;
                          "
              >
                <el-image
                  :src="qrcodeSrc"
                  style="width: 100px; height: 100px"
                ></el-image>
                <el-button size="mini" @click.native="downloadQrcode"
                >下载
                </el-button
                >
                <span class="el-button el-button--default el-button--mini" style="margin-left:8px"  @click="enLarageQrcode">查看</span>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="项目简称" prop="prjAs">
              <el-input
                v-model="dataForm.prjAs"
                placeholder="请填写项目简称"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目区域" prop="areName">
              <el-input
                v-model="dataForm.areName"
                disabled
                placeholder="请填写项目区域"
              ></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="承接单位" prop="contractorName">
              <el-input disabled v-model="dataForm.contractorName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="执行单位" prop="contractId">
              <avue-input-tree
                v-if="formType != 'view'"
                :props="{ label: 'title', value: 'id',leaf:'leaf' }"
                checkStrictly
                default-expand-all
                v-model="dataForm.contractId"
                placeholder="请选择执行单位"
                :dic="organDic"
                :nodeClick="nodeClick"
              ></avue-input-tree>
              <el-input v-else v-model="dataForm.contractName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>

          <el-col :span="8">
            <el-form-item label="项目经理" prop="pmCame">
              <el-input
                v-model="dataForm.pmCame"
                placeholder="请输入项目经理"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话" prop="phoneNumber">
              <el-input
                v-model="dataForm.phoneNumber"
                placeholder="请输入联系电话"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="入网日期" prop="prjJoinDate">
              <avue-date
                style="width: 100%"
                format="yyyy-MM-dd"
                valueFormat="yyyy-MM-dd"
                :picker-options="pickerOptions"
                v-model="dataForm.prjJoinDate"
                placeholder="请选择入网日期"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="卫星电话数量" prop="phoneCount">
              <!-- <el-input v-model="dataForm.phoneCount" placeholder="请填写卫星电话数量" oninput="value = value.replace(/[^\d]/g,'')"  maxlength="50"></el-input> -->
              <el-input
                v-model="dataForm.phoneCount"
                placeholder="请填写卫星电话数量"
                oninput="value = value.replace(/[^\d]/g,'').slice(0, 3)"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="项目坐标" prop="coordinate">
              <el-input
                @focus="openMap"
                v-model="dataForm.coordinate"
                placeholder="请填写项目坐标"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目位置" prop="areaId">
              <el-cascader
                ref="prjAreaCascader"
                filterable
                style="width: 100%"
                v-model="dataForm.areaId"
                :options="areaDic"
                @change="handleChangeArea"
              ></el-cascader>
              <!-- <avue-input-tree checkStrictly v-model="dataForm.areaId" placeholder="请选择" :dic="areaDic"  @change="handleChangeArea"></avue-input-tree> -->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="详细位置" prop="areaRestock">
              <el-input
                v-model="dataForm.areaRestock"
                placeholder="请填写"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="所属板块" prop="prjType">
              <el-select
                v-model="dataForm.prjType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in prjTypeList"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目状态" prop="stage">
              <el-select
                v-model="dataForm.stage"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in stageList"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开始日期" prop="startWorking">
              <avue-date
                style="width: 100%"
                type="date"
                :picker-options="planStartDateOptions"
                format="yyyy-MM-dd"
                valueFormat="yyyy-MM-dd"
                v-model="dataForm.startWorking"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结束日期" prop="planEndDate">
              <avue-date
                type="date"
                style="width: 100%"
                format="yyyy-MM-dd"
                valueFormat="yyyy-MM-dd"
                :picker-options="planEndDateOptions"
                v-model="dataForm.planEndDate"
                placeholder="请选择入网日期"
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="纳入安全管理" prop="prjInfoState">
              <el-select
                v-model="dataForm.prjInfoState"
                placeholder="请选择">
                <el-option
                  v-for="item in yes_no"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="境内外" prop="isDomestic">
              <el-select
                v-model="dataForm.isDomestic"
                placeholder="请选择境内外"
                disabled
              >
                <el-option
                  v-for="item in prjDomesticList"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item label="项目进度" prop="progress">
              <!-- <el-slider v-model="dataForm.progress"></el-slider> -->
               <el-input-number size="medium"  v-model="dataForm.progress" :min="0" :max="100"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目类型" prop="businessTypeCode">
              <el-select
                v-model="dataForm.businessTypeCode"
                placeholder="请选择项目类型"
              >
                <el-option
                  v-for="item in businessTypeMap"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 加入项目扫描二维码弹框 -->
    <qrcodeDialog ref="qrcodeDialog"></qrcodeDialog>
    <BaiduMap v-model="dataForm" ref="BaiduMap" @change="handleMapSave"></BaiduMap>
  </el-container>
</template>

<style scoped lang="scss">
form {
  padding: 10px !important;
  box-sizing: border-box !important;
}
</style>
